import { Injectable, Injector, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Subject, of, throwError } from 'rxjs';
import { catchError, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

import { User } from '@app/core/models/user.model';
import { SystemDefaults } from '../models/systemDefaults.model';
import { CURRENT_USER_DATA } from '../constants';
import { AuthRepository } from '../repositories/auth.repository';
import { ApiResponseInterface } from '../interfaces';
import { LocalStorageService } from '@src/app/core/services/local-storage.service';
import { AuthActions } from '@src/app/features/auth/core/store';
import { HttpService } from '@app/core/services/http.service';
import { trimValue } from '@app/core/shared/helpers/trim-value';
import { ConfirmDialogComponent } from '../shared/components/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends HttpService {
  private errors: string[] = [];
  public loggedIn: boolean = false;
  public userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public systemDefaultsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private tokenRefreshTimeout: any;
  private _onDestroy$: Subject<void> = new Subject<void>();
  // @ViewChild(ConfirmDialogComponent, { static: false }) confirmDialCompRef!: ConfirmDialogComponent;

  constructor(
    injector: Injector,
    private authRepository: AuthRepository,
    private localStorageService: LocalStorageService,
    private store: Store,
    @Inject(PLATFORM_ID) private platformId: object, // To handle SSR/browser checks
  ) {
    super(injector);
    this.setUserDetails();
    this.setSystemDefaults();
  }

  /**
   * Determines whether the app is running in a browser environment.
   */
  private isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  /**
   * Initializes and sets the user details from local storage or given data.
   */
  setUserDetails(user: any = this.localService.get(CURRENT_USER_DATA)): void {
    if (user != null) {
      this.loggedIn = true;
      this.user = new User(user || {});
      this.userSubject.next(this.user);
    }
  }

  /**
   * Sets system defaults from local storage or given data.
   */
  setSystemDefaults(systemDefaults: any = this.localService.get('sid-system-defaults')): void {
    if (systemDefaults != null) {
      this.systemDefaults = new SystemDefaults(systemDefaults || {});
      this.systemDefaultsSubject.next(this.systemDefaults);
    }
  }

  /**
   * Logs the user into the system and retrieves required data.
   */
  login() {
    return this.authRepository.getUserDetails().pipe(
      switchMap((response: any): any => {
        if (response?.status === this.constantList.SUCCESS_STATUS) {
          if (!response.body) {
            this.showMessage(`User doesn't exist!`, 'warning');
            return of(null);
          }
          return this.authRepository.getBaseURL().pipe(
            map((systemDefaultsResponse: any) => {
              return { userResponse: response.body, systemDefaultsResponse };
            }),
            catchError(err => throwError(() => this.handleErrorMessages(err))),
          );
        }
        return null;
      }),
      tap(({ userResponse, systemDefaultsResponse }: any) => {
        if (userResponse) {
          this.setLocalSession(userResponse, systemDefaultsResponse);
          this.loggedIn = true;
          return userResponse;
        }
        this.loggedIn = false;
        return null;
      }),
      shareReplay(1),
      catchError(err => throwError(() => this.handleErrorMessages(err))),
    );
  }

  /**
   * Automatically refreshes the token before it expires.
   */
  public startTokenRefresh(): void {
    if (!this.isBrowser()) return;

    const token = this.localStorageService.getToken();
    if (!token) {
      this.clearTokenRefreshTimer();
      return;
    }

    const decodedToken: any = this.decodeJwtToken(token);
    if (!decodedToken || !decodedToken.exp) {
      this.clearTokenRefreshTimer();
      return;
    }

    const expiresInMs = decodedToken.exp * 1000 - Date.now();
    const refreshTime = expiresInMs - 5 * 60 * 1000; // Refresh 5 minutes before expiry

    if (refreshTime > 0) {
      this.clearTokenRefreshTimer();
      this.tokenRefreshTimeout = setTimeout(() => this.refreshToken(), refreshTime);
    } else {
      this.logout();
    }
  }
  /**
   * Retrieves the current user information from the backend and updates the local session.
   */
  public howIAm(): void {
    const currentUrl = this.router.url;
    this.authRepository
      .getUserDetails()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe({
        next: (response: unknown) => {
          const res = response as ApiResponseInterface<any>;

          if (res.body) {
            this.localService.setDataInLocalStorage({ key: CURRENT_USER_DATA, value: res.body });
            this.setUserDetails(); // Updates the BehaviorSubject with user details
          }
        },
        error: error => {
          console.error('Error in howIAm:', error);
        },
      });
  }

  /**
   * Sets the redirect URL based on the current URL and user session.
   *
   * @return {void} This function does not return a value.
   */
  setRedirectUrl() {
    const currentUrl = this.router.url;
    if (currentUrl.includes('no-access')) {
      return;
    }
    if (currentUrl === '/' && window.location.pathname) {
      localStorage.setItem(
        'returnUrl',
        JSON.stringify({
          url: window.location.pathname,
          time: new Date().getTime(),
          id:
            (localStorage.getItem(CURRENT_USER_DATA) &&
              JSON.parse(localStorage.getItem(CURRENT_USER_DATA) || 'null').employeeId) ||
            'notFound',
        }),
      );
    } else {
      if (!currentUrl.includes('no-access') && !currentUrl.includes('otp')) {
        localStorage.setItem(
          'returnUrl',
          JSON.stringify({
            url: currentUrl,
            time: new Date().getTime(),
            id:
              (localStorage.getItem(CURRENT_USER_DATA) &&
                JSON.parse(localStorage.getItem(CURRENT_USER_DATA) || 'null').employeeId) ||
              'notFound',
          }),
        );
      }
    }
  }

  /**
   * Returns a boolean indicating whether the server is running or not.
   *
   * @return {boolean} A boolean value indicating whether the server is running or not.
   */
  public get isServer(): boolean {
    return !this.localService.isBrowser;
  }

  /**
   * Refreshes the token by calling the backend API.
   */
  public refreshToken(): void {
    this.authRepository.resetToken().subscribe({
      next: (response: any) => {
        if (response.accessToken) {
          this.localStorageService.setToken(response.accessToken);
          this.startTokenRefresh(); // Reschedule token refresh
          this.howIAm(); // Sync the user session with the backend
        } else {
          this.handleTokenFailure(); // Handle cases where the token is invalid
        }
      },
      error: (error: any) => {
        console.error('Error refreshing token:', error);
        this.handleTokenFailure(); // Handle errors like network failures or invalid refresh token
      },
    });
  }

  /**
   * Clears the token refresh timer.
   */
  private clearTokenRefreshTimer(): void {
    if (this.tokenRefreshTimeout) {
      clearTimeout(this.tokenRefreshTimeout);
      this.tokenRefreshTimeout = null;
    }
  }

  /**
   * Logs out the user and clears all session data.
   */
  public async logout(): Promise<void> {
    this.clearTokenRefreshTimer();
    this.store.dispatch(AuthActions.logout());
    this.localStorageService.clearDataInLocalStorage();
    this.setUserDetails(null);
    this.setSystemDefaults(null);
    this.localService.clearLocalStorageExceptOneKey(['sid-app-config', 'language']);
    this._onDestroy$.next();
    this._onDestroy$.complete();
    if (this.isBrowser()) {
      await this.router.navigate(['/auth/login']);
    }
  }

  /**
   * Decodes a JWT token.
   */
  private decodeJwtToken(token: string): any {
    try {
      const payload = token.split('.')[1];
      return JSON.parse(atob(payload));
    } catch (e) {
      return null;
    }
  }

  /**
   * Checks if the user is logged in.
   */
  public isLoggedIn(): boolean {
    return !!this.localService.get(CURRENT_USER_DATA);
  }

  /**
   * Handles saving session data to local storage.
   */
  setLocalSession(authResult: any, systemDefaultsResponse: any) {
    trimValue(authResult.firstName);
    trimValue(authResult.lastName);
    this.localService.set({ key: 'sid-permissions', value: authResult.permissions || [] });
    this.updateUserDetails(authResult);
    this.updateSystemDefaults(systemDefaultsResponse.body);
  }

  /**
   * Updates user details in local storage.
   */
  updateUserDetails(user: any = this.user): void {
    user['expiry_date'] = new Date();
    this.localService.setDataInLocalStorage({ key: CURRENT_USER_DATA, value: user });
    this.setUserDetails(user);
  }

  /**
   * Updates system defaults.
   */
  updateSystemDefaults(systemDefaults: any = this.systemDefaults): void {
    this.localService.setDataInLocalStorage({
      key: 'sid-system-defaults',
      value: systemDefaults,
    });
    this.setSystemDefaults(systemDefaults);
  }

  /**
   * Checks user permissions.
   */
  checkPermission(permission: string | string[]): boolean | void {
    const userData = this.localService.get(CURRENT_USER_DATA);
    if (!userData?.permissions) {
      return false;
    }
    if (Array.isArray(permission)) {
      return permission.some(perm => userData.permissions.includes(perm));
    }
    return userData.permissions.includes(permission);
  }

  /**
   * Handles token refresh failure.
   */
  handleTokenFailure(): void {
    // Use a notification service instead of alert
    this.showMessage('Session expired. Please refresh your page and login again.', 'error');
    
    // Log the user out
    this.logout();
  }


  /**
   * Handles token refresh failure.
   */
  handleTokenFailureMessage(): void {
    // Use a notification service instead of alert
    this.showMessage('Session expired. Please refresh your page and login again.', 'error')
  }
}
